import './App.css';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './components/Home'
import About from './components/About'
import Projects from './components/Projects';
import Soccer from './components/Soccer';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Sinusoids from './blogs/Sinusoids';
import Negation from './blogs/Negation';
import MTRegression from './blogs/MTRegression';
import GLMDistributions from './blogs/GLMDistributions';
import RegressionInversion from './blogs/RegressionInversion';
import WeightedAveraging from './blogs/WeightedAveraging';
import KmeansExtended from './blogs/KmeansExtended';

function App() {
  
  useEffect(() => {
      // Your function logic
      const menuToggle = document.getElementById('mobile-menu');
      const navList = document.querySelector('.sidebar');

      const toggleNav = () => {
          navList.classList.toggle('show');
      };

      // Attach the event listener after the component has mounted
      menuToggle.addEventListener('click', toggleNav);

      // Clean up the event listener when the component is unmounted
      return () => {
          menuToggle.removeEventListener('click', toggleNav);
      };
  }, []); 

  useEffect(() => {

      const navList = document.getElementById('sidebar');

      const handleLinkClick = (event) => {
        if (event.target.classList.contains('link')) {
          // Your code to collapse the navbar
          navList.classList.toggle('show');
        }
      };

      navList.addEventListener('click',handleLinkClick);

      return () => {
        navList.removeEventListener('click',handleLinkClick);
      };

    },[]
  );

  return (

    <BrowserRouter>
      <div className="container">
        
        <div className="menu-toggle" id="mobile-menu" >
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div className='sidebar' id='sidebar'>
            <h1>JASON LIVINGSTON</h1>
            <h5>TheDataProfessional.com</h5>
            <ul>
                <li>
                  <Link to="/" className='link'>HOME</Link>
                </li>
                <li>
                  <Link to="/about" className='link'>ABOUT</Link>
                </li>
                <li>
                  <Link to="/projects" className='link'>DATA SCIENCE</Link>
                </li>
                <li>
                  <Link to="/soccer" className='link'>SOCCER</Link>
                </li>
                <li>
                  <Link to="/contact" className='link'>CONTACT</Link>
                </li>
            </ul>
        </div>

        <Routes>
          <Route exact path='/' element= { <Home /> } />
          <Route exact path='/projects' element= { <Projects /> } />
          <Route exact path='/soccer' element= { <Soccer /> } />
          <Route exact path='/about' element= { <About /> } />
          <Route exact path='/contact' element= { <Contact /> } />
          <Route exact path='/blogbooks/sinusoids' element= { <Sinusoids /> } />
          <Route exact path='/blogbooks/ai-negation' element= { <Negation /> } />
          <Route exact path='/blogbooks/multi-target-regression' element= { <MTRegression /> } />
          <Route exact path='/blogbooks/glm-distributions' element= { <GLMDistributions /> } />
          <Route exact path='/blogbooks/regression-inversion' element= { <RegressionInversion /> } />
          <Route exact path='/blogbooks/weighted-average-regression' element= { <WeightedAveraging /> } />
          <Route exact path='/blogbooks/kmeans-extended' element= { <KmeansExtended /> } />
          <Route path='*' element= { <NotFound /> } />
        </Routes>
      
      </div>
    
    </BrowserRouter>

  );
}

export default App;
